<template>
    <div v-if="data" class="py-1 px-4 text-gray-800 grid grid-cols-1 md:grid-cols-6 w-full rounded-md">
        <!-- Name Field -->
        <div class="col-span-1 md:col-span-2" v-tippy :content="fetchLabel(data.name)">
            <input 
                v-if="edited"
                v-model="data.name"
                @click.stop="$emit('fieldNameClick', data)"
                :class="!data.name || !isValidName(data.name) ? 'border border-solid border-red-300' : ''"
                type="text" 
                class="section_name w-full md:w-48 bg-transparent border-0 outline-none p-1 text-md text-base-content focus:bg-gray-200 rounded focus:border-none border-b border-solid border-gray-300 truncate" 
                
            />

            <span v-else 
                class="section_name bg-transparent truncate w-full md:w-48 border-0 outline-none p-1 text-sm text-base-content font-normal truncate">
                {{ fieldName }}
            </span>
        </div>
            <!-- Query Field -->
        <div class="col-span-1 md:col-span-2">
            <input v-model="stringifiedQuery" :disabled="!edited" :class="!data.query || edited ? 'border-b border-line border-gray-200' : ''"
                type="text" class="bg-transparent w-full md:w-42 border-0 outline-none text-md text-gray-600 focus:bg-gray-100 rounded-md overflow-ellipsis" />
        </div>

        <!-- Default Checkbox -->
        <div class="flex justify-center col-span-1 items-center">
            <input :value="data.default" v-model="data.default" type="checkbox" class="checkbox w-4 h-4 border-gray-500 outline-none"
                :class="!edited ? 'pointer-events-none opacity-50' : 'pointer'" />
        </div>

        <!-- Edit/Save/Delete Buttons (when in editing mode) -->
        <div v-if="edited" class="flex justify-evenly col-span-1 items-center">
            <div v-if="this.$route.params.action !== 'view'" @click.stop="onSave(data)"
                class="h-8 w-8 flex items-center justify-center rounded-full hover:text-white-text transition-all duration-150">
                <font-awesome-icon icon="save" class="cursor-pointer text-sm text-green-500"
                    v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }" />
            </div>
            <div class="py-1">
                <font-awesome-icon v-if="this.$route.params.action !== 'view'" v-allow="'block.delete'"
                    v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }"
                    icon="times" @click.stop="close" class="cursor-pointer text-red-500 w-5 h-5 hover:text-red-focus text-md" v-tippy content="Delete Block" />
            </div>
        </div>

        <!-- Edit/Delete Buttons (when not in editing mode) -->
        <div v-else class="flex justify-evenly col-span-1 items-center">
            <div v-if="this.$route.params.action !== 'view'" class="h-8 w-8 flex items-center justify-center rounded-full hover:text-white-text transition-all duration-150"
                @click.stop="onEdit()">
                <font-awesome-icon icon="edit" class="cursor-pointer text-sm text-primary"
                    v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }"
                    :class="edited ? 'pointer-events-none opacity-50' : 'pointer'" />
            </div>
            <div class="py-1">
                <font-awesome-icon v-if="this.$route.params.action !== 'view'" v-allow="'block.delete'"
                    v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }"
                    icon="trash" @click.stop="onDelete(data)" class="cursor-pointer text-red-500 w-4 h-4 hover:text-red-focus text-sm"
                    v-tippy content="Delete Block" :class="edited ? 'pointer-events-none opacity-50' : 'pointer'" />
            </div>
        </div>

        <!-- Delete Field Modal -->
        <deleteFieldModal
            :open="deleteFieldModalOpen"
            @close="closeDeleteFieldModal"
            v-if="workflowData"
            :workflowData="workflowData"
        />
    </div>

</template>
<script>

import { fetchLabel} from "@shared/utils/functions"
import axios from "@/axios";
import deleteFieldModal from "./delete-pre-build-query.vue";


export default {
    name: "add-block",
    
    data() {
        return{
            stringifiedQuery: JSON.stringify(this.data.query),
            edited: false,
            deleteFieldModalOpen: false,
            workflowData:{},
            isNameValid: true,
            previousData: '',
            previousQuery: '',
      }
    },
    components: {
        deleteFieldModal
    },
    async mounted() {
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    methods:{
        fetchLabel,
        onEdit() {
            if (this.$route.params.action == 'view') {
                return
            }
            if (!this.data.name) {
                this.$toast.error("Query name cannot be empty");
                return;
            }
            this.previousData = this.data.name;
            this.previousQuery = this.stringifiedQuery;
            this.edited = !this.edited;

        },
        async onSave(value)
        {
            this.edited = false;
            if(this.isValidJson)
                if (!this.data.name) {
                    this.$toast.error("Query name cannot be empty");
                    return;
                }
                else{
                const parsed = JSON.parse(this.stringifiedQuery); // Parse to remove backslashes
                    let payload;
                    payload= {
                        name: value.name,
                        query: parsed,
                    }
                    if (value.default) {
                        payload.default = value.default;
                    }           
                    try {
                        let url = `/tenant-check/${this.$route.params.id}/pre-build-query/${value.id}`;
                        let { data } = await axios.put(url, payload);
                        this.$toast.success(data?.detail);
                        
                    } catch (error) {
                        console.log(error)
                        this.$toast.error(error.response.data.detail);
                    }
                }
            else{
                this.$toast.error("Not a valid Json")
                this.stringifiedQuery = this.previousQuery;

            }
        },
        getFieldName(field) {
            return field.name;
        },
        getFieldQuery(field) {
            return field.query;
        },
        close(){
            this.edited = false;
            this.data.name =  this.previousData;
            this.stringifiedQuery = this.previousQuery;

        },
        async onDelete(value)
        {
            try {
                let url = `/tenant-check/${this.$route.params.id}/pre-build-query/${value.id}/workflow`;
                let { data } = await axios.get(url);
                const result = this.checkAllArraysEmpty(data);
                if(!result){
                    this.deleteFieldModalOpen = true
                    this.workflowData = data
                }
                else{
                    this.deleteQuery(value)
                }
                
            } catch (error) {
                console.log(error)
                this.$toast.error(error.response.data.detail);
            }
        },
        closeDeleteFieldModal() {
            this.deleteFieldModalOpen = false;
        },
        checkAllArraysEmpty(data) {
            for (const key in data) {
                if (Array.isArray(data[key]) && data[key].length > 0) {
                    return false; // If any array is not empty, return false
                }
            }
            return true; // If all arrays are empty, return true
        },
        async deleteQuery(value){
            try {
                let url = `/tenant-check/${this.$route.params.id}/pre-build-query/${value.id}`;
                let { data } = await axios.delete(url);
                this.$toast.success(data.detail);
                this.$emit("refresh");
                
            } catch (error) {
                console.log(error)
                this.$toast.error(error.response.data.detail);
            }
        },
        isValidName(name) {
            // Regular expression to check if the name is valid
            const regex = /^(?!\s)(?!.*\s$)[A-Za-z0-9 ]+$/;
            return regex.test(name);  // Returns true if valid, false otherwise
        }
   },
   computed:{
        fieldName() {
            return this.getFieldName(this.data);
        },
        fieldQuery(){
            return this.getFieldQuery(this.data);
        },
        isValidJson() {
            try {
                JSON.parse(this.stringifiedQuery);
                return true; // Valid JSON
            } catch (e) {
                return false; // Invalid JSON
            }
        },
   }
};
</script>

<style lang="scss" scoped>

</style>
